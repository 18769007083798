define('client/utils/analytics', ['exports', 'client/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.processEmail = undefined;


  var processEmail = function processEmail(userModel) {
    if (_environment.default.environment === 'production') {
      console.log('App Version: ', _environment.default.APP.version);
    } else if (_environment.default.environment === 'development') {
      return;
    }

    if (typeof Honeybadger !== 'undefined' && typeof Honeybadger.resetContext === 'function') {
      Honeybadger.resetContext({
        id: userModel.get('id'),
        username: userModel.get('name'),
        email: userModel.get('email'),
        appVersion: _environment.default.APP.version
      });
    }

    // WOOTRIC
    // window.wootric_survey_immediately = true; // Shows survey immediately for testing purposes.  TODO: Comment out for production.
    window.wootricSettings = {
      email: userModel.get('email'), // TODO: The current logged in user's email address.
      created_at: userModel.get('created_at'), // TODO: The current logged in user's sign-up date as a 10 digit Unix timestamp in seconds.
      account_token: 'NPS-abf023e0' // This is your unique account token.
    };
    if (typeof dataLayer !== 'undefined') {
      dataLayer.push({ 'event': 'wootricSetup' });
    }

    // FULL STORY
    if (typeof FS !== 'undefined' && typeof FS.identify === 'function') {
      FS.identify(userModel.get('id'), {
        displayName: userModel.get('name'),
        email: userModel.get('email'),
        timezone_str: userModel.get('timezone'),
        isSuperAdmin_bool: userModel.get('isSuperAdmin'),
        account_id: userModel.get('account_id'),
        account_name: userModel.get('account_name'),
        created_at: userModel.get('created_at'),
        profile_names: userModel.get('isSuperAdmin') ? [] : userModel.get('corporates').mapBy('company_name'),
        profile_ids: userModel.get('isSuperAdmin') ? [] : userModel.get('corporates').mapBy('id'),
        role: userModel.get('role'),
        appVersion: _environment.default.APP.version
      });
    }

    // HEAP ANALYTICS
    if (typeof heap !== 'undefined' && typeof heap.identify === 'function') {
      heap.identify(userModel.get('email'));
      heap.addUserProperties({
        'Name': userModel.get('name'),
        'ID': userModel.get('id'),
        'Timezone': userModel.get('timezone'),
        'IsSuperAdmin': userModel.get('isSuperAdmin'),
        appVersion: _environment.default.APP.version
      });
    }

    // PENDO.io
    if (typeof pendo !== 'undefined' && typeof pendo.initialize === 'function') {
      pendo.initialize({
        visitor: {
          id: userModel.get('id'),
          email: userModel.get('email'),
          name: userModel.get('name'),
          timezone: userModel.get('timezone'),
          isSuperAdmin: userModel.get('isSuperAdmin')
        },
        account: {
          id: userModel.get('account_id'),
          name: userModel.get('account_name')
        }
      });
    }

    // aptrinsic
    if (typeof aptrinsic !== 'undefined' && typeof aptrinsic === 'function') {
      try {
        aptrinsic("identify", {
          //User Fields
          id: userModel.get('id'),
          email: userModel.get('email'),
          name: userModel.get('name'),
          timeZone: userModel.get('timezone'),
          isSuperAdmin: userModel.get('isSuperAdmin'),
          isLiteVersion: userModel.get('liteVersion'),
          signUpDate: userModel.get('created_at'),
          firstName: userModel.get('name').split(' ').slice(0, -1).join(' '),
          lastName: userModel.get('name').split(' ').slice(-1).join(' ')
        }, {
          //Account Fields
          id: userModel.get('account_id'),
          name: userModel.get('account_name')
        });
      } catch (e) {
        console.error('Error sending data to aptrinsic:', e.message, e);
      }
    }

    // INSPECTLET
    if (typeof __insp !== 'undefined') {
      __insp.push(['identify', userModel.get('email')]);
      __insp.push(['tagSession', { email: userModel.get('email'),
        name: userModel.get('name'),
        isSuperAdmin: userModel.get('isSuperAdmin') }]);
    }

    if (typeof Intercom !== "undefined") {
      Intercom("boot", {
        app_id: "h5c06dp7",
        name: userModel.get("name"),
        user_id: 'analyst-' + userModel.get("id"),
        email: userModel.get("email"),
        user_hash: userModel.get('intercomHash'),
        timeZone: userModel.get('timezone'),
        userSignUpDate: userModel.get("createdAt"),
        account_id: userModel.get('account_id'),
        account_name: userModel.get('account_name'),
        profile_names: userModel.get('isSuperAdmin') ? [] : userModel.get('corporates').mapBy('company_name'),
        profile_ids: userModel.get('isSuperAdmin') ? [] : userModel.get('corporates').mapBy('id'),
        role: userModel.get('role')
      });
    } else {
      console.error("Intercom not available to initialize");
    }
  }; /* globals __insp */
  /* globals dataLayer */
  /* globals FS */
  /* globals heap */
  /* globals pendo */
  /* globals aptrinsic */

  exports.processEmail = processEmail;
});